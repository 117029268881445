/* eslint-disable consistent-return */
/* eslint-disable no-tabs */
/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable no-return-await */
/* eslint-disable semi */
/* eslint-disable comma-dangle */
const HttpRequest = {
  errorHandle(data) {
    const res = false
    if (data.code === 403) {
      // log
    } else if (data.code === 401) {
      // log
    }
    return res
  },

  process: async ($http, method, url, params = {}) => {
    // const loadingFunc = config.loading ? config.loading : null
    // on loadding
    // if (loadingFunc) loadingFunc()
    const userProfile = JSON.parse(localStorage.getItem('BUSOtokenPlatform'))
    let options = ''
    if (userProfile && userProfile.access_token) {
      options = {
        headers: {
          Authorization: `Bearer ${userProfile.access_token}`
        }
      }
    }
    let response = null
    // $http.onError(error => {
    //   if (error.statusCode === 500) {
    //     alert('Request Error!')
    //   }
    //   // Tip: error.response will be undefined if the connection dropped to the server
    //   // Tip: You can use error.response.data to get response message
    //   // Tip: You can return an object or Promise as fallback response to avoid rejection
    // })
    if (method === 'get') {
      response = await $http.get(url, options)
    } else if (method === 'post') {
      response = await $http.post(url, params, options)
    } else if (method === 'put') {
      response = await $http.put(url, params, options)
    } else if (method === 'delete') {
      response = await $http.delete(url, options)
    }
    if (response.data && response.data.code !== 200) {
      // const isError = HttpRequest.errorHandle(response.data)
      // if (isError) return
    }
    // off loadding
    // if (loadingFunc) loadingFunc.close()

    return response || {}
  },
}
export default {
  get: async ($http, url, params = {}) => {
    return await HttpRequest.process($http, 'get', url, params);
  },
  post: async ($http, url, params = {}) => {
    return await HttpRequest.process($http, 'post', url, params);
  },
  put: async ($http, url, params = {}) => {
    return await HttpRequest.process($http, 'put', url, params);
  },
  delete: async ($http, url, params = {}) => {
    return await HttpRequest.process($http, 'delete', url, params);
  }
}
