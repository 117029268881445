<template>
  <section id="theme">
    <b-row>
      <b-col cols="3">
        <b-form-group label="Chọn danh mục" label-cols-md="12">
          <v-select
            v-model="select"
            label="name"
            :reduce="x => x.id"
            :options="categoryTheme"
            class="mb-2"
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="" label-cols-md="12">
          <b-button
            class="mt-2"
            @click="filterData"
          >Filter</b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(item, index) in themes"
        :key="index"
        cols="6"
        md="3"
        class="theme-item"
      >
        <div class="inner">
          <div class="img-box">
            <div class="picture">
              <img class="img" :src="item.avatar" :alt="item.name">
              <div class="control">
                <b-button
                  v-b-modal.modal-form
                  class="control-btn mb-1 btn-light-blue"
                  @click="sendData(item.id)"
                >Tạo web</b-button>
                <b-button
                  class="control-btn mb-1 btn-blue"
                  @click="linkDemo(item.id, item.site_id)"
                >Xem thử</b-button>
                <b-button
                  v-if="devmod"
                  v-b-modal.modal-form-update
                  class="control-btn btn-blue"
                  @click="getDetail(item.id)"
                >Chỉnh sửa Theme</b-button>
              </div>
            </div>
          </div>
          <div class="detail-box">
            <div class="title">{{ item.name }}</div>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <Pagination
          v-model="themeList"
          :per-page="perPage"
          :current-page="currentPage"
          @items-per-page="PaginationPerPage"
          @pagechanged="onPageChange"
        />
      </b-col>
    </b-row>
    <b-modal id="modal-form" title="Registration Form" ok-title="Save" centered size="lg" @ok="validationForm">
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <h3>Account Information</h3>
          <b-form-group label="*Full name:" label-cols-md="12">
            <validation-provider
              #default="{ errors }"
              name="Full name"
              rules="required"
            >
              <b-form-input
                v-model="model.fullname"
                :state="errors.length > 0 ? false : null"
                placeholder="Full name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="*Phone:" label-cols-md="12">
            <validation-provider
              #default="{ errors }"
              name="Phone"
              rules="required|integer"
            >
              <b-input-group
                prepend="(+84)"
                class="input-group-merge"
              >
                <b-form-input
                  v-model="model.phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="929495xxx"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="*Email:" label-cols-md="12">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                v-model="model.email"
                :state="errors.length > 0 ? false : null"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="*Password:" label-cols-md="12">
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-form-input
                v-model="model.password"
                :state="errors.length > 0 ? false : null"
                placeholder="Password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <hr>
          <h3>Shop Information</h3>
          <b-form-group label="*Name store:" label-cols-md="12">
            <validation-provider
              #default="{ errors }"
              name="Name store"
              rules="required"
            >
              <b-form-input
                v-model="model.name"
                :state="errors.length > 0 ? false : null"
                placeholder="Name store"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="*Domain name:" label-cols-md="12">
            <validation-provider
              #default="{ errors }"
              name="Domain name"
              rules="required"
            >
              <b-input-group
                append=".busodevelopers.com"
                class="input-group-merge"
              >
                <b-form-input
                  v-model="model.domain_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="buso"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Select language" label-cols-md="12">
            <v-select
              v-model="model.select_language"
              label="text"
              :reduce="x => x.value"
              :options="languageOption"
            />
          </b-form-group>
          <b-form-group label="Select currency" label-cols-md="12">
            <v-select
              v-model="model.select_currency"
              label="text"
              :reduce="x => x.value"
              :options="currencyOption"
            />
          </b-form-group>
          <b-form-group label="Select Data" label-cols-md="12">
            <v-select
              v-model="model.select_data"
              label="text"
              :reduce="x => x.value"
              :options="dataOption"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal id="modal-form-update" title="Update Information" ok-title="Save" centered size="lg" @ok="validationFormUpdate">
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <h3>Account Information</h3>
          <b-form-group label="*Name:" label-cols-md="12">
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                v-model="model2.name"
                :state="errors.length > 0 ? false : null"
                placeholder="Name"
                @input="generateSlug(model2.name)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="*Slug:" label-cols-md="12">
            <validation-provider
              #default="{ errors }"
              name="Slug"
              rules="required"
            >
              <b-form-input
                v-model="model2.slug"
                :state="errors.length > 0 ? false : null"
                placeholder="Slug"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Chọn danh mục" label-cols-md="12">
            <v-select
              v-model="model2.component_categories_id"
              label="name"
              :reduce="x => x.id"
              :options="categoryTheme"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group>
            <div
              class="update-avatar align-items-center justify-content-center"
            >
              <b-aspect aspect="1:1" class="position-relative">
                <b-img-lazy
                  :src="model2.avatar"
                  fluid
                  thumbnail
                  rounded
                  style="position: absolute;"
                />
                <div
                  class="fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"
                >
                  <b-form-file
                    accept=".jpg, .png, .gif"
                    class="w-100 h-100 d-flex align-items-center justify-content-center img-box"
                    @change="previewFiles"
                  />
                  <b-button
                    variant="outline-primary"
                    class="btn-icon rounded-circle btn-add"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
              </b-aspect>
            </div>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BForm,
  BFormInput,
  // BInputGroupAppend,
  BInputGroup,
  BFormFile,
  BImgLazy,
  BAspect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
// import general from '@/mixins/index'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormGroup,
    BForm,
    BFormInput,
    // BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BImgLazy,
    BAspect,
  },
  data() {
    return {
      required,
      devmod: true,
      select: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 12,
      categoryTheme: [],
      themes: [],
      themeList: [
        {
          name: 'Công nghệ 1',
          link: '/#',
          img: '',
        },
        {
          name: 'Công nghệ 1',
          link: '/#',
          img: '',
        },
        {
          name: 'Công nghệ 1',
          link: '/#',
          img: '',
        },
        {
          name: 'Công nghệ 1',
          link: '/#',
          img: '',
        },
        {
          name: 'Công nghệ 1',
          link: '/#',
          img: '',
        },
      ],
      model: {
        select_language: 'vi',
        select_currency: 'VND',
        select_data: 1,
      },
      model2: {
        avatar: null,
      },
      languageOption: [
        {
          text: 'Việt Nam',
          value: 'vi',
        },
        {
          text: 'English',
          value: 'en',
        },
      ],
      currencyOption: [
        {
          text: 'Việt Nam đồng (VNĐ)',
          value: 'VND',
        },
        {
          text: 'Dollar (USD $)',
          value: 'USD',
        },
      ],
      dataOption: [
        {
          text: 'Có dữ liệu mẫu',
          value: 1,
        },
        {
          text: 'Không có dữ liệu mẫu',
          value: 0,
        },
      ],
    }
  },
  created() {
    this.loadComponentCate()
    this.loadList()
  },
  mounted() {
  },
  methods: {
    generateSlug(name) {
      this.model2.slug = this.convertToSlug(name)
    },
    filterData() {
      this.loadList(this.select)
    },
    async loadList(id) {
      let params = ''
      if (id) {
        params = `component_categories_id=${id}`
      }
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/templates?status=2&${params}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.themeList = []
          // eslint-disable-next-line array-callback-return
          res.map(val => {
            this.themeList.push({
              id: val.id_root,
              site_id: val.site_id,
              name: val.name,
              avatar: val.avatar,
            })
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadComponentCate() {
      // const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/component_categories`,
        )
        if (result.status === 200) {
          const res = result.data.data.items
          this.categoryTheme = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async getDetail(id) {
      // const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/template/${id}`,
        )
        if (result.data.status) {
          this.model2 = result.data.data
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async linkDemo(id, sid) {
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/domain/${id}?site_id=${sid}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          const anchor = document.createElement('a')
          if (res.name.includes('https://')) {
            anchor.href = `${res.name}`
          } else {
            anchor.href = `https://${res.name}`
          }
          anchor.target = '_blank'
          anchor.click()
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    sendData(id) {
      this.model.id = id
    },
    async validationForm() {
      const params = {
        email: this.model.email,
        password: this.model.password,
        domain_name: `${this.model.domain_name}.busodevelopers.com`,
        store_name: this.model.name,
        fullname: this.model.fullname,
        template_id: this.model.id,
        language: this.model.select_language,
        currency: this.model.select_currency,
        invoice_detail: JSON.stringify({}),
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_CLIENT_API_URL}/auth/create`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Theme success',
                  },
                })
                this.loginAdmin({
                  email: this.model.email,
                  password: this.model.password,
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async validationFormUpdate() {
      const params = {
        name: this.model2.name,
        slug: this.model2.slug,
        is_active: 1,
        component_categories_id: String(this.model2.component_categories_id),
      }
      if (this.model2.avatar !== null) {
        params.avatar = this.model2.avatar
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/template/${this.model2.id}`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Theme success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async loginAdmin(param) {
      const res = await Request.post(
        this.$http,
        'https://beta-backendapi.busodevelopers.com/login',
        param,
      )
      if (res.data.status === false) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Login fail',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(this.showError(res.data.error, ',')),
          },
        })
      } else {
        // window.location.href = `https://beta-app.busodevelopers.com/me?token=${res.data.data.access_token}&type=2`
        const anchor = document.createElement('a')
        anchor.href = `https://beta-app.busodevelopers.com/me?token=${res.data.data.access_token}&type=2`
        anchor.target = '_blank'
        anchor.click()
      }
    },
    PaginationPerPage(data) {
      this.themes = data
    },
    onPageChange(page) {
      this.currentPage = page
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model2.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="sass">
#theme
  .theme-item
    margin-bottom: 2rem
    .inner
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%)
      position: relative
      padding: 1rem
      &:hover
        .img-box
          .picture
            .control
              opacity: 1
              visibility: visible
      .img-box
        position: relative

        .picture
          overflow: hidden
          display: block
          position: relative
          aspect-ratio: 1/1.5

          .img
            height: unset
            width: 100%
            max-width: none
            display: block
            object-fit: contain
            position: absolute
            top: 0
            left: 0

          .control
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            opacity: 0
            visibility: hidden
            transition: all 450ms
            z-index: 2
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center

            &::before
              content: ""
              position: absolute
              top: 0
              left: 0
              width: 100%
              height: 100%
              background: linear-gradient(to top,#5a73b8,#53c1ae)
              z-index: 1
              opacity: .75

            &-btn
              z-index: 2
              box-shadow: 0 3px 6px rgb(0 0 0 / 16%)
            .btn-blue
              border-color: #5a73b8 !important
              background-color: #5a73b8 !important
            .btn-light-blue
              border-color: #53c1ae !important
              background-color: #53c1ae !important

      .detail-box
        margin-top: 1rem
        text-align: center

        .title
          overflow: hidden
          display: -webkit-box
          -webkit-line-clamp: 1
          -webkit-line-clamp: 1
          -webkit-box-orient: vertical
          margin-bottom: 0
          font-weight: 500

</style>
<style lang="scss" scoped>
.update-avatar {
  width: 250px;
}
#getFile {
  display: none;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
